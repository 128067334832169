export default {
  async nuxtServerInit ({ dispatch, state }, { req }) {
    if (!process.client) {
      // PAGE LOCALE
      dispatch('page/update', { name: 'locale', data: state.i18n.locale })

      // PAGE CURRENCY
      dispatch('page/update', { name: 'currency', data: process.env.currency })

      // AUTH COOKIE
      if (this.$cookies.get('token') && this.$cookies.get('refreshToken')) {
        const token = this.$cookies.get('token')
        const refreshToken = this.$cookies.get('refreshToken')

        if (token && refreshToken) {
          dispatch('auth/set', { token, refreshToken })
        }
      }

      // // GET ADDRESS
      await this.$api.default.address()
        .then((res) => {
          console.log('address', res)
          dispatch('page/update', { name: 'mainAddress', data: res.address })

          if (res.countryId && !this.$cookies.get('address')) {
            const address = {
              company: null,
              tin: null,
              firstName: null,
              lastName: null,
              countryId: res.countryId,
              address: null,
              addressNumber: null,
              postCode: null,
              city: null,
              email: null,
              phone: null
            }
            this.$cookies.set('address', address, { path: '/', domain: process.env.cookieDomain, maxAge: 60 * 60 * 24 * 365 })
          }
        })
        .catch(() => {
          console.error('error')
        })

      // GET CATEGORIES
      await this.$api.default.categories()
        .then((res) => {
          // console.log(res)
          dispatch('page/update', { name: 'categories', data: res.result })
          dispatch('page/update', { name: 'countryId', data: res.countryId })
        })
        .catch(() => {
          // console.error('error')
        })

      // GET COUNTRIES
      await this.$api.default.countries()
        .then((countries) => {
          dispatch('page/update', { name: 'countries', data: countries })
        })
        .catch(() => {
          // console.error('error')
        })
    }
  }
}
