<script>
export default {
  props: {
    component: { type: [Object, Array], default: null },
    tag: { type: String, default: null },
    options: { type: Object, default: () => {} },
    props: { type: Object, default: null },
    scoped: { type: String, default: null }
  },
  render (createElement) {
    // let nodes = this.component
    // if (!Array.isArray(nodes)) {
    //   nodes = [nodes]
    // }
    // return createElement(this.tag, this.options, nodes)

    if (this.component && this.scoped && this.tag) {
      return createElement(this.tag, this.options,
        this.props
          ? this.component.$scopedSlots[this.scoped](this.props)
          : this.component.$slots[this.scoped])
    } else {
      return this.component
    }
  }
}
</script>
