<template>
  <div class="menu-mobile modal-card">
    <section class="modal-card-header" />
    <section class="menu-card-body menu-content">
      <div class="container main-container">
        <location-settings v-if="checkPageCheckout" active-mobile />
        <div class="menu-items">
          <span class="small-header">
            {{ $t('fields.categories') }}
          </span>
          <category-tile v-for="(category, index) in categories" :key="index" :category="category" />
        </div>
      </div>
    </section>
    <section class="menu-footer">
      <div class="container main-container">
        <div class="buttons">
          <nuxt-link v-if="!_user" :to="localePath('auth-login')" tag="div" custom>
            <b-button expanded @click.native="closeMenu">
              {{ $t('header.sign_in') }}
            </b-button>
          </nuxt-link>
          <b-dropdown v-else aria-role="list">
            <template #trigger>
              <b-button type="is-light" icon-left="user" expanded>
                {{ $t('header.hello', { user: _user ? _user.firstName : '' }) }}
              </b-button>
            </template>
            <b-dropdown-item
              custom
              has-link
              position="is-bottom-right"
              aria-role="menuitem"
            >
              <nuxt-link v-if="check('user')" :to="localePath('profile-orders')" @click.native="closeMenu">
                {{ $t('header.myAccount') }}
              </nuxt-link>
              <nuxt-link v-if="check('seller')" :to="localePath('admin')" @click.native="closeMenu">
                {{ $t('header.seller_panel') }}
              </nuxt-link>
            </b-dropdown-item>
            <b-dropdown-item aria-role="menuitem" @click.native="logoutUser()">
              {{ $t('actions.logout') }}
            </b-dropdown-item>
          </b-dropdown>
          <nuxt-link :to="_user && check('seller') ? localePath('admin-messenger') : localePath('messenger')">
            <b-button icon-left="messages" expanded @click.native="closeMenu">
              {{ $t('messenger.title') }} ({{ countNewMessages }})
            </b-button>
          </nuxt-link>
        </div>
        <b-button type="is-primary" icon-left="shopping-cart" expanded @click.stop="$store.dispatch('page/isOpenedCartToggle')" @click.native="closeMenu">
          {{ $t('cart.title') }} ({{ getCount }})
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import mixinCountNewMessages from '~/marketplace-front-core/mixin/countNewMessages'
import LocationSettings from '~/marketplace-front-core/components/locationSettings/Header'
import CategoryTile from '@/components/layout/CategoryTile'

export default {
  components: { CategoryTile, LocationSettings },
  mixins: [mixinCountNewMessages],
  computed: {
    categories () {
      return this.$store.state.page.categories
    },
    checkPageCheckout () {
      return !this._checkPage('checkout')
    },
    getCart () {
      return this.$store.state.page.cart
    },
    getCount () {
      return this.getCart && this.getCart.quantity ? this.getCart.quantity : 0
    }
  },
  methods: {
    check (key) {
      if (this._user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }
        const check = Object.values(availableRoles).filter((val) => {
          return this._user.roles.includes(val)
        })

        return check.length > 0
      } else {
        return false
      }
    },
    closeMenu () {
      this.$store.dispatch('page/update', { name: 'isMenuOpen', data: false })
    },
    logoutUser () {
      this.logout()
      this.closeMenu()
    }
  }
}
</script>
