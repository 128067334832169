import { Validator } from 'vee-validate'
import lodashClonedeep from 'lodash.clonedeep'
import validatorsLang from '~/marketplace-front-core/locales/validators'
import sortArrayObjects from '~/marketplace-front-core/utils/sortArrayObjects'

Validator.localize(validatorsLang)

export default ({ app, store }) => {
  const sortCountries = (validate) => {
    const countries = lodashClonedeep(store.state.page.countries)
    if (countries) {
      countries.forEach((country) => {
        country.name = app.i18n.t('countries.' + country.iso)
      })
      countries.sort(sortArrayObjects('name'))
      store.dispatch('page/update', { name: 'countries', data: countries })
    }
  }

  app.$moment.locale(store.state.i18n.locale)
  Validator.localize(store.state.i18n.locale, validatorsLang[store.state.i18n.locale])
  app.$cookies.set('locale', store.state.i18n.locale, { path: '/', maxAge: 60 * 60 * 24 * 365 })
  sortCountries()

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    app.$moment.locale(newLocale)
    Validator.localize(newLocale, validatorsLang[newLocale])
    sortCountries()
  }

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    store.dispatch('page/update', { name: 'locale', data: newLocale })
    app.$cookies.set('locale', newLocale, { path: '/', maxAge: 60 * 60 * 24 * 365 })
  }
}
