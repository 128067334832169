<template>
  <div class="logo-and-address">
    <nuxt-link :to="localePath('index')">
      <picture>
        <!-- <source srcset="~/static/images/logo.webp" type="image/webp">
        <source srcset="~/static/images/logo.png" type="image/png"> -->
        <img src="~/static/images/logo.svg" alt="" width="214.931" height="38">
      </picture>
    </nuxt-link>
    <location-settings v-if="checkPageCheckout" />
    <div class="menu-button-container is-hidden-tablet" @click="toggleMenu()">
      <div :class="$store.state.page.isMenuOpen ? 'menu-button open' : 'menu-button'">
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
    <b-modal
      v-model="$store.state.page.isMenuOpen"
      full-screen
      has-modal-card
      animation="fade"
      scroll="keep"
      :can-cancel="false"
      class="is-hidden-desktop menu-mobile-modal"
    >
      <menu-mobile />
    </b-modal>
  </div>
</template>

<script>
import LocationSettings from '~/marketplace-front-core/components/locationSettings/Header'
import MenuMobile from '@/components/layout/MenuMobile'

export default {
  components: { LocationSettings, MenuMobile },
  computed: {
    checkPageCheckout () {
      return !this._checkPage('checkout')
    }
  },
  methods: {
    toggleMenu () {
      this.scrollToTop('smooth')
      this.$store.dispatch('page/update', { name: 'isMenuOpen', data: !this.$store.state.page.isMenuOpen })
    }
  }
}
</script>
