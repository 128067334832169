<template>
  <div class="header-circle-buttons is-hidden-mobile">
    <!-- User -->
    <nuxt-link v-if="!_user" :to="localePath('auth-login')" tag="div" custom>
      <p class="circle-text">
        {{ $t('header.sign_in') }}
      </p>
      <div class="circle is-gray">
        <img src="~/static/images/user.svg" alt="">
      </div>
    </nuxt-link>
    <!-- <div v-if="_user" class="circle-button">
    </div> -->
    <b-dropdown v-else aria-role="list">
      <template #trigger>
        <div class="circle-button">
          <p class="circle-text">
            {{ $t('header.hello', { user: _user ? _user.firstName : '' }) }}
          </p>
          <div class="circle is-gray">
            <div>
              TS
            </div>
          </div>
        </div>
      </template>
      <b-dropdown-item
        custom
        has-link
        position="is-bottom-right"
        aria-role="menuitem"
      >
        <nuxt-link v-if="check('user')" :to="localePath('profile-orders')">
          {{ $t('header.myAccount') }}
        </nuxt-link>
        <nuxt-link v-if="check('seller')" :to="localePath('admin')">
          {{ $t('header.seller_panel') }}
        </nuxt-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="menuitem" @click="logout">
        {{ $t('actions.logout') }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- Messenger -->
    <nuxt-link :to="_user && check('seller') ? localePath('admin-messenger') : localePath('messenger')" tag="div" class="circle-button">
      <div class="circle">
        <img src="~/static/images/messenger.svg" alt="">
      </div>
      <div v-if="countNewMessages && countNewMessages > 0" class="indicator">
        <span class="count-new-messages">{{ countNewMessages }}</span>
      </div>
    </nuxt-link>
    <!-- Cart -->
    <div v-if="checkPageCheckout" class="circle-button is-primary" @click.stop="$store.dispatch('page/isOpenedCartToggle')">
      <div class="circle is-primary">
        <img src="~/static/images/cart.svg" alt="">
      </div>
      <div class="indicator">
        {{ getCount }}
      </div>
    </div>
  </div>
</template>

<script>
import mixinCountNewMessages from '~/marketplace-front-core/mixin/countNewMessages'

export default {
  mixins: [mixinCountNewMessages],
  computed: {
    getCart () {
      return this.$store.state.page.cart
    },
    getCount () {
      return this.getCart && this.getCart.quantity ? this.getCart.quantity : 0
    },
    checkPageCheckout () {
      return !this._checkPage('checkout')
    }
  },
  methods: {
    toggleOpenCart () {
      this.$store.dispatch('page/isOpenedCartToggle')
    },
    check (key) {
      if (this._user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }
        const check = Object.values(availableRoles).filter((val) => {
          return this._user.roles.includes(val)
        })

        return check.length > 0
      } else {
        return false
      }
    }
  }
}
</script>
