<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <template v-if="model && model.id">
          {{ $t('header.locationSettings.modal.editAddress') }}
        </template>
        <template v-else>
          {{ $t('header.locationSettings.modal.addNewAddress') }}
        </template>
      </p>
      <button
        type="button"
        class="delete"
        @click="close()"
      />
    </header>
    <section class="modal-card-body">
      <b-loading :is-full-page="false" :active.sync="loading" />
      <div v-if="model">
        <div class="columns">
          <div class="column">
            <span class="label">{{ $t('fields.clientType') }}</span>
            <div class="address-type">
              <b-radio
                v-model="isCompany"
                :native-value="false"
                :class="{ 'active': !isCompany }"
              >
                {{ $t('fields.privateClients') }}
              </b-radio>
              <b-radio
                v-model="isCompany"
                :native-value="true"
                :class="{ 'active': isCompany }"
              >
                {{ $t('fields.businessClients') }}
              </b-radio>
            </div>
          </div>
        </div>
        <div v-show="isCompany" class="columns">
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('company')}"
              :message="errors.first('company')"
            >
              <template slot="label">
                {{ $t('fields.company') }}
              </template>
              <b-input
                v-model="model.company"
                v-validate="{ required: !model.firstName }"
                name="company"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('fields.tin')"
              :type="{'is-danger': errors.has('tin')}"
              :message="errors.first('tin')"
            >
              <!-- v-validate="'required'" -->
              <b-input
                v-model="model.tin"
                name="tin"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Imię -->
            <b-field
              :type="{'is-danger': errors.has('firstName')}"
              :message="errors.first('firstName')"
            >
              <template slot="label">
                {{ $t('fields.firstName') }}
              </template>
              <b-input
                v-model="model.firstName"
                v-validate="{ required: !model.company }"
                name="firstName"
              />
            </b-field>
          </div>
          <div class="column">
            <!-- Nazwisko -->
            <b-field
              :type="{'is-danger': errors.has('lastName')}"
              :message="errors.first('lastName')"
            >
              <template slot="label">
                {{ $t('fields.lastName') }}
              </template>
              <b-input
                v-model="model.lastName"
                v-validate="{ required: model.firstName }"
                name="lastName"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('address')}"
              :message="errors.first('address')"
            >
              <template slot="label">
                {{ $t('fields.address') }}
              </template>
              <b-input
                v-model="model.address"
                v-validate="'required'"
                name="address"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('addressNumber')}"
              :message="errors.first('addressNumber')"
            >
              <template slot="label">
                {{ $t('fields.houseNumber') }}
              </template>
              <b-input
                v-model="model.addressNumber"
                v-validate="'required'"
                name="addressNumber"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('postCode')}"
              :message="errors.first('postCode')"
            >
              <template slot="label">
                {{ $t('fields.postCode') }}
              </template>
              <b-input
                v-model="model.postCode"
                v-validate="'required'"
                name="postCode"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('city')}"
              :message="errors.first('city')"
            >
              <template slot="label">
                {{ $t('fields.city') }}
              </template>
              <b-input
                v-model="model.city"
                v-validate="'required'"
                name="city"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('country')}"
              :message="errors.first('country')"
              :label="$t('fields.country')"
            >
              <b-select
                v-model="model.countryId"
                v-validate="'required'"
                size="is-default"
                name="country"
                expanded
              >
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              :type="{'is-danger': errors.has('email')}"
              :message="errors.first('email')"
            >
              <template slot="label">
                {{ $t('fields.email') }}
              </template>
              <b-input
                v-model="model.email"
                v-validate="'required|email'"
                name="email"
              />
            </b-field>
          </div>
          <div class="column">
            <form-phone
              v-model="model.phone"
              name="phone"
              size="is-default"
            >
              {{ $t('fields.phone') }} <span class="label-optional">{{ $t('fields.optional') }}</span>
            </form-phone>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <b-button :label="$t('actions.save')" type="is-primary" @click="save()" />
        <b-button :label="$t('actions.close')" @click="close()" />
      </div>
    </footer>
  </div>
</template>

<script>
import FormPhone from '~/marketplace-front-core/components/form/phone'

export default {
  components: { FormPhone },
  provide () { return { $parentValidator: this.$validator } },
  data () {
    return {
      loading: false,
      isCompany: false,
      model: null
    }
  },
  computed: {
    countries () {
      return this.$store.state.page.countries
    }
  },
  watch: {
    isCompany (value) {
      if (!value) {
        this.model.tin = ''
        this.model.company = ''
      }
    }
  },
  methods: {
    open (item) {
      this.model = {
        id: null,
        company: '',
        tin: '',
        firstName: '',
        lastName: '',
        city: '',
        address: '',
        addressNumber: '',
        postCode: '',
        phone: '',
        countryId: this.$store.state.page.countryId,
        email: this._user ? this._user.email : ''
      }

      if (item) {
        this.isCompany = !!item.company

        this.model.id = item.id
        this.model.company = item.company
        this.model.tin = item.tin
        this.model.firstName = item.firstName
        this.model.lastName = item.lastName
        this.model.city = item.city
        this.model.address = item.address
        this.model.addressNumber = item.addressNumber
        this.model.postCode = item.postCode
        this.model.phone = item.phone
        this.model.countryId = item.countryId || this.$store.state.page.countryId
        this.model.email = item.email
      }

      this.$emit('status', true)

      this.$nextTick(() => {
        this.$validator.reset()
      })
    },
    save () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          this.$api.address.save(this.model)
            .then((res) => {
              this.isCompany = false

              this.$store.dispatch('page/update', { name: 'addresses', data: res.addresses })
              this.$store.dispatch('page/update', { name: 'mainAddress', data: res.main })

              if (res.cookies) {
                this.$cookies.set('address', res.cookies, { path: '/', domain: process.env.cookieDomain, maxAge: 60 * 60 * 24 * 365 })
              }

              this.close()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    close () {
      this.$emit('status', false)
    }
  }
}
</script>
