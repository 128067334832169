<template>
  <div :class="['change-address', { 'is-hidden-mobile': !activeMobile }]">
    <div class="address-value">
      <img :src="getCountry.flag" alt="" class="flag-sm">
      <template v-if="checkIsMainAddress()">
        {{ `${mainAddress.city || ''} ${mainAddress.city && ( mainAddress.address || mainAddress.addressNumber ) ? ',' : ''} ${mainAddress.address || ''} ${mainAddress.addressNumber || ''}` }}
      </template>
      <template v-else>
        {{ `${getCountry && getCountry.name ? getCountry.name : ''}` }}
        <span class="link pl-2" @click="openModal()">
          {{ $t('header.locationSettings.change') }}
        </span>
      </template>
    </div>
    <div>
      {{ $t(`locales.${_locale}`) }} | {{ _currency }}
      <span v-if="checkIsMainAddress()" class="link pl-2" @click="openModal()">
        {{ $t('header.locationSettings.change') }}
      </span>
    </div>
    <modal ref="modal" />
  </div>
</template>

<script>
import Modal from '~/marketplace-front-core/components/locationSettings/Modal'

export default {
  components: { Modal },
  props: {
    activeMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCountry () {
      const mainAddress = this.$store.state.page.mainAddress
      const countryId = mainAddress && mainAddress.countryId ? mainAddress.countryId : this.$store.state.page.countryId

      const country = this.$store.state.page.countries.find(x => x.id === countryId)

      return {
        name: country ? country.name : '',
        flag: country && country.iso && country.iso.toLowerCase() && this._flags[country.iso.toLowerCase()] ? this._flags[country.iso.toLowerCase()] : this._flags.unknown
      }
    },
    mainAddress () {
      return this.$store.state.page.mainAddress
    }
  },
  methods: {
    openModal () {
      this.$refs.modal.open()
    },
    checkIsMainAddress () {
      return this.mainAddress && (this.mainAddress.city || this.mainAddress.address || this.mainAddress.addressNumber)
    }
  }
}
</script>
