<template>
  <div class="cart">
    <div v-if="getCart && getCart.orders && getCart.orders.length > 0" class="cart-products">
      <div class="mb-5" @click="closeCart">
        <nuxt-link :to="localePath('checkout')" @click.native="$store.dispatch('page/update', {name: 'isMenuOpen', data: false})">
          <b-button
            :type="shoppingColor"
            class="is-fullwidth shopping-button"
            icon-left="lock"
            rounded
          >
            {{ $t('cart.checkout') }}
          </b-button>
        </nuxt-link>
      </div>
      <div v-for="(order, index) in getCart.orders" :key="index * 256">
        <div v-for="(product, index2) in order.products" :key="product.orderOfferProductId" class="cart-single-product">
          <div class="columns">
            <div class="column is-narrow">
              <div v-if="product.filePivot && product.filePivot.file" class="product-img img-responsive">
                <b-image
                  ratio="1by1"
                  :src="getImageBySize(product.filePivot.file, 'medium').src"
                  :webp-fallback="getImageBySize(product.filePivot.file, 'medium').webpFallback"
                  class="skeleton-image-placeholder border-img"
                  lazy
                >
                  <template #placeholder>
                    <b-skeleton />
                  </template>
                </b-image>
              </div>
            </div>
            <div class="column">
              <div class="product-detail flex-start">
                <span class="detail-props product-title">
                  {{ product.description.name }}
                  <nuxt-link v-if="product.tokenToDeal" class="tag is-light is-info" :to="localePath({ name: 'groupDeal', params: { identify: product.tokenToDeal } })">
                    {{ $t('groupDeal.tag') }}
                  </nuxt-link>
                </span>
                <span class="detail-value">
                  <b-button size="is-small" rounded :loading="loadingChangeCart" icon-left="clear" @click="cartDeleteProduct(product.orderOfferProductId, product)" />
                </span>
              </div>
            </div>
          </div>
          <!-- TODO Sprawdzic czy na produkcji bedzie dzialac -->
          <div v-if="product.sizes && (product?.sizes?.width || product.sizes.height)" class="product-detail">
            <div class="details">
              <span class="detail">{{ $t('fields.width') }}: <span class="size-with-unit">{{ product?.sizes?.width }} {{ sizeType(product?.sizes) }}</span></span><span class="detail">{{ $t('fields.height') }}: <span class="size-with-unit">{{ product.sizes.height }} {{ sizeType(product.sizes) }}</span></span>
            </div>
          </div>

          <!-- Stare dodatki -->
          <!-- <div v-for="addon in product.addons" :key="addon.id" class="ml-2">
            <div class="addon mt-4">
              <div class="addon-overview">
                <b-icon size="is-size-9" icon="plus" />
                <div v-if="addon.filePivot && addon.filePivot.file" class="addon-img">
                  <b-image
                    ratio="1by1"
                    :src="getImageBySize(addon.filePivot.file, 'small').src"
                    :webp-fallback="getImageBySize(addon.filePivot.file, 'small').webpFallback"
                    class="skeleton-image-placeholder border-img"
                    lazy
                  >
                    <template #placeholder>
                      <b-skeleton />
                    </template>
                  </b-image>
                </div>
                <span class="has-text-weight-bold">{{ addon.description.name }}</span>
              </div>
              <div v-if="addon.sizes.height && addon.sizes.height" class="product-detail ml-1">
                <div class="details">
                  <span class="detail">{{ $t('fields.width') }}: {{ addon.sizes.height }}</span><span
                    class="detail"
                  >{{ $t('fields.height') }}: {{ addon.sizes.width }}</span>
                </div>
              </div>
            </div>
          </div> -->

          <div class="addons-summary">
            <div class="summary-item">
              <span class="item-name">
                x{{ product.quantity }} {{ product.description.name }}
              </span>
              <span class="item-price">
                {{ $t('products.pricePerItem', {price: toCurrency(product.summary.perItem, null, product.currency)}) }}
              </span>
            </div>
            <div v-for="(addon, indexAddon) in product.addons" :key="'addon_'+indexAddon" class="summary-item">
              <ul class="bulletpoints">
                <li> x{{ product.quantity }} {{ addon.description.name }}</li>
              </ul>
              <span class="item-price">
                {{ $t('products.pricePerItem', {price: toCurrency(addon.summary.price, null, product.currency)}) }}
              </span>
            </div>
          </div>
          <div class="product-detail price-detail">
            <span>{{ $t('cart.price') }}:</span>
            <span class="cart-product-price">{{ toCurrency(product.summary.totalPrice, null, product.currency) }}</span>
          </div>
          <div class="product-detail">
            <span class="detail-props p-r-20">{{ $t('cart.quantity') }}:</span>
            <span class="detail-value">
              <b-numberinput
                :key="refreshQuantity"
                :value="product.quantity"
                controls-position="compact"
                size="is-small"
                class="is-pulled-right quantity-input"
                type="is-white"
                :min="1"
                :controls="!loadingChangeCart"
                :loading="loadingChangeCart"
                :disabled="loadingChangeCart"
                @input="x => cartChangeProductQuantity(x, product.orderOfferProductId)"
              />
            </span>
          </div>
          <client-note v-if="_project === 'schuette' || _project === 'rzaska'" :order-index="index" :product-index="index2" />
          <hr v-if="index2 + 1 < order.products.length">
        </div>
      </div>
      <div class="m-t-20" @click="closeCart">
        <nuxt-link :to="localePath('checkout')" @click.native="$store.dispatch('page/update', {name: 'isMenuOpen', data: false})">
          <b-button
            :type="shoppingColor"
            class="is-fullwidth shopping-button"
            icon-left="lock"
            rounded
          >
            {{ $t('cart.checkout') }}
          </b-button>
        </nuxt-link>
      </div>
    </div>
    <div v-else class="cart-is-empty center">
      <p>{{ $t('cart.empty') }}</p>
    </div>
  </div>
</template>

<script>
import ClientNote from './ClientNote'
import cart from '~/marketplace-front-core/mixin/cart'
export default {
  name: 'Cart',
  components: { ClientNote },
  mixins: [cart],
  data () {
    return {
      shoppingColor: process.env.shoppingColor
    }
  },
  computed: {
    getCart () {
      return this.$store.state.page.cart
    }
  },
  mounted () {
    this.$api.cart.get()
      .then((res) => {
        this.$store.dispatch('page/update', { name: 'cart', data: res })
      })
      .catch(() => {
        this.$store.dispatch('page/update', { name: 'cart', data: null })
      })
  },
  methods: {
    closeCart () {
      this.$store.dispatch('page/update', { name: 'isOpenedCart', data: false })
    }
  }
}
</script>
