<template>
  <div class="main-body">
    <layout-header />
    <nuxt />
    <layout-footer />
    <global-layout />
  </div>
</template>

<script>
import GlobalLayout from '~/marketplace-front-core/components/layout/GlobalLayout'
import LayoutHeader from '~/components/layout/Header'
import LayoutFooter from '~/components/layout/Footer'

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    GlobalLayout
  }
}
</script>
