<template>
  <div class="form-control phone-control">
    <b-field :type="{'is-danger': errors.has(name)}" :label-position="labelPosition">
      <template slot="label">
        <slot />
      </template>
      <b-autocomplete
        v-model="phone.directional"
        class="phone-directional"
        :size="size"
        :open-on-focus="true"
        :data="filtredCountryDirectional"
        field="directional"
        :disabled="disabled"
        @blur="changePhoneNumberToInput"
        @input="inputHandler()"
      >
        <template slot-scope="props">
          <span :class="activePropClass(props.option.directional)">
            {{ props.option.directional }} - {{ $t('countries.' + props.option.iso) }}
          </span>
        </template>
      </b-autocomplete>
      <b-input
        :id="name"
        v-model="phone.number"
        :name="name"
        expanded
        type="text"
        :size="size"
        :disabled="disabled"
        @blur="changePhoneNumberToInput"
        @input="inputHandler()"
      />
    </b-field>

    <p class="help is-danger">
      {{ errors.first(name) }}
    </p>
  </div>
</template>

<script>
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'

export default {
  inject: { $validator: '$parentValidator' },
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'phone'
    },
    size: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      countryDirectional: [],
      phone: {
        directional: '',
        number: ''
      }
    }
  },
  computed: {
    filtredCountryDirectional () {
      return this.countryDirectional.filter((option) => {
        return option.directional
          .toString()
          .toLowerCase()
          .includes(this.phone.directional.toLowerCase())
      })
    },
    countries () {
      return this.$store.state.page.countries
    }
  },
  watch: {
    value (val) {
      if (val !== this.getNumber()) {
        this.parseValue()
      }
    }
  },
  mounted () {
    const countries = this._clone(this.countries)
    countries.forEach((element) => {
      element.directional = '+' + element.countryCode
    })
    this.countryDirectional = countries

    this.setDirectional()
    if (this.value) {
      this.parseValue()
    }
  },
  methods: {
    changePhoneNumberToInput () {
      const phone = this.phone.directional + this.phone.number
      let valid = null

      if (parseMax(phone)) {
        valid = parseMax(phone)
      } else if (parseMax(this.phone.number)) {
        valid = parseMax(this.phone.number)
      }

      if (valid) {
        this.phone.directional = '+' + valid.countryCallingCode
        this.phone.number = valid.nationalNumber
      }
    },
    getNumber () {
      const phone = this.phone.directional + this.phone.number
      let parsedNumber = phone

      if (parseMax(phone)) {
        parsedNumber = parseMax(phone).number
      } else if (parseMax(this.phone.number)) {
        parsedNumber = parseMax(this.phone.number).number
      } else if (this.countryDirectional.find(x => x.directional === phone)) {
        parsedNumber = this.value
      }

      return parsedNumber
    },
    inputHandler () {
      this.$emit('input', this.getNumber())
    },
    activePropClass (value) {
      return {
        'has-text-weight-bold': this.phone.directional === value
      }
    },
    parseValue () {
      if (this.value) {
        const parsedPhoneNumber = parseMax(this.value)
        if (parsedPhoneNumber) {
          this.phone.directional = '+' + parsedPhoneNumber.countryCallingCode
          this.phone.number = parsedPhoneNumber.nationalNumber
        } else {
          this.phone.number = this.value
          this.setDirectional()
        }
      } else {
        this.setDirectional()
      }
    },
    setDirectional () {
      if (this.$store.state.page.countryId) {
        const find = this.countryDirectional.find(x => x.id === this.$store.state.page.countryId)
        if (find) { this.phone.directional = find.directional }
      }
    }
  }
}
</script>
