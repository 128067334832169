export const coreState = () => ({
  webSocket: null,
  unreadMessage: [],
  isWebSocketConnection: false,
  lastMessage: null,
  connectedTicket: null,
  limitMessage: 20,
  loadingTicket: false,
  loadingTicketList: false,
  loadingTicketListMore: false,
  loadingMessageMore: false,
  loadingCreateTicket: false,
  loadingAssignEmployees: false,
  loadingCloseTicket: false,
  loadingChangeOrderId: false
})

export const coreGetters = {
  currentTimeMessenger () {
    return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
  }
}

export const coreMutations = {
  setWebSocket (store, data) {
    store.webSocket = data
  },
  setUnreadMessage (store, data) {
    store.unreadMessage = data
  },
  setIsWebSocketConnection (store, data) {
    store.isWebSocketConnection = data
  },
  setLastMessage (store, data) {
    store.lastMessage = data
  },
  setConnectedTicket (store, data) {
    store.connectedTicket = data
  },
  setLoadingTicket (store, data) {
    store.loadingTicket = data
  },
  setLoadingTicketList (store, data) {
    store.loadingTicketList = data
  },
  setLoadingTicketListMore (store, data) {
    store.loadingTicketListMore = data
  },
  setLoadingMessageMore (store, data) {
    store.loadingMessageMore = data
  },
  setLoadingCreateTicket (store, data) {
    store.loadingCreateTicket = data
  },
  setLoadingAssignEmployees (store, data) {
    store.loadingAssignEmployees = data
  },
  setLoadingCloseTicket (store, data) {
    store.loadingCloseTicket = data
  },
  setLoadingChangeOrderId (store, data) {
    store.loadingChangeOrderId = data
  }
}

export const coreActions = {
  connection ({ commit }, webSocket) {
    commit('setWebSocket', webSocket)
  },

  close ({ commit, state, dispatch }) {
    const params = {
      action: 'close-connection'
    }

    dispatch('send', params)

    state.webSocket.close()

    commit('setIsWebSocketConnection', false)
  },

  onopen ({ commit, state }) {
    state.webSocket.onopen = async function (wb) {
      await wb.currentTarget.send(JSON.stringify({ action: 'join' }))
      commit('setIsWebSocketConnection', true)
    }
  },

  setIsWebSocketConnection ({ commit }, type) {
    commit('setIsWebSocketConnection', type)
  },

  ticketsList ({ dispatch }, filter) {
    const params = {
      action: 'ticket-list-specific-user',
      requiredId: filter.requiredId,
      limit: filter.limit,
      filter: filter.filter || null,
      myTickets: filter.myTickets || false, // Moje
      othersTickets: filter.othersTickets || false, // Innych
      unassigned: filter.unassigned || false, // Nieprzepisane
      ticketOpen: filter.ticketOpen || false, // Otwarte
      ticketClosed: filter.ticketClosed || false, // Zamknięte
      ticketArchived: filter.ticketArchived || false, // Archiwum
      lang: filter.lang || [],
      ticketsCount: filter.ticketsCount
    }

    dispatch('send', params)

    if (filter.ticketsCount === 0) {
      dispatch('changeLoadingTicketList', true)
    } else {
      dispatch('changeLoadingTicketListMore', true)
    }
  },

  unreadMessage ({ commit }, params) {
    commit('setUnreadMessage', params)
  },

  waitForWebSocketConnection ({ state, dispatch }, params) {
    setTimeout(() => {
      if (params !== null) {
        if (state.isWebSocketConnection) {
          params['Content-Language'] = this.state.i18n.locale
          state.webSocket.send(JSON.stringify(params))
        } else {
          dispatch('waitForWebSocketConnection', params)
        }
      }
    }, '50')
  },

  assignOrder ({ dispatch }, data) {
    const params = {
      action: 'assign-order',
      orderId: data.orderId,
      ticketId: data.ticketId
    }

    dispatch('send', params)

    dispatch('changeLoadingChangeOrderId', true)
  },

  ticketData ({ dispatch }, ticketId) {
    const params = {
      action: 'ticket-data',
      ticketId
    }

    dispatch('send', params)
  },

  send ({ state, dispatch, getters }, params) {
    if (!state.isWebSocketConnection) {
      dispatch('waitForWebSocketConnection', params)
    } else {
      params['Content-Language'] = this.state.i18n.locale
      state.webSocket.send(JSON.stringify(params))
      console.log(`%c${getters.currentTimeMessenger} %cSEND TO API:%c ${params.action}`, 'color: #C4D9FA;', 'background: #3865AC; font-size: 0.75rem; color: #C4D9FA;', 'color: #C4D9FA;', params)
    }
  },

  sendMessage ({ dispatch }, { message, attachments = [] }) {
    const params = {
      action: 'message',
      message,
      attachments
    }
    dispatch('send', params)
  },

  // if WS was restarting and send second time message
  sendLastMessage ({ dispatch, state }) {
    if (state.connectedTicket !== null) {
      dispatch('connectToRoom', { ticketId: state.connectedTicket, ticketsCount: 0 })
    }

    dispatch('send', state.lastMessage)
  },

  connectToRoom ({ commit, dispatch, state }, filter) {
    const params = {
      action: 'connect',
      ticketId: filter.ticketId,
      limit: state.limitMessage,
      ticketsCount: filter.ticketsCount
    }

    commit('setConnectedTicket', filter.ticketId)

    dispatch('send', params)

    dispatch('changeLoadingTicket', true)
  },

  createRoom ({ dispatch }, { type, id }) {
    const params = {
      action: 'create-ticket',
      type,
      id,
      lang: this.state.i18n.locale
    }
    dispatch('send', params)

    dispatch('changeLoadingCreateTicket', true)
  },

  typing ({ dispatch }, action) {
    dispatch('send', action)
  },

  closeRoom ({ dispatch }, id) {
    const params = {
      action: 'ticket-status-change',
      ticketIds: [id],
      newStatus: 'CLOSED'

    }
    dispatch('send', params)

    dispatch('changeLoadingCloseTicket', true)
  },

  assignRoomTo ({ dispatch }, { assignTo, unassignFrom = [], ticketId }) {
    const params = {
      action: 'ticket-assign-to',
      assignTo,
      ticketId,
      unassignFrom
    }

    dispatch('send', params)

    dispatch('changeLoadingAssignEmployees', true)
  },

  editMessage ({ dispatch }, { messageId, newMessage }) {
    const params = {
      action: 'edit-message',
      messageId,
      newMessage
    }

    dispatch('send', params)
  },

  switchTicketLanguage ({ dispatch }, { ticketId, lang }) {
    const params = {
      action: 'change-ticket-lang',
      ticketId,
      lang
    }
    dispatch('send', params)
  },

  loadMoreMessages ({ dispatch, state }, { lastMessageId }) {
    const params = {
      action: 'message-history',
      lastMessageId,
      limit: state.limitMessage
    }
    dispatch('send', params)

    dispatch('changeLoadingMessageMore', true)
  },

  onMessage ({ commit, state, getters }) {
    state.webSocket.onmessage = function (message) {
      const data = (JSON.parse(message.data))
      switch (data.type) {
        case 'count-unread':
          commit('setUnreadMessage', data)
          break
        case 'JWT-token-info':
          // dispatch('refreshToken', data)
          break

        default:
          console.warn(`${getters.currentTimeMessenger} UNKNOWN TYPE 2: `, data.type, data)
      }
    }
  },

  // async refreshToken (message) {
  //   if (message.message === 'Expired JWT Token') {
  //     this.$store.dispatch('websocket/close')
  //     await this.$api.auth.refreshToken(this.$store.state.auth.refreshToken)
  //       .then((res) => {
  //         this.$store.dispatch('auth/set', res)
  //       }).then(() => {
  //         setTimeout(() => {
  //           this._vm.initWebSocket()
  //         }, '5000')
  //       })
  //   }
  // },

  changeLoadingTicket ({ commit }, data) {
    console.log('loading ticket', data)
    commit('setLoadingTicket', data)
  },

  changeLoadingTicketList ({ commit }, data) {
    console.log('loading ticket list', data)
    commit('setLoadingTicketList', data)
  },

  changeLoadingTicketListMore ({ commit }, data) {
    console.log('loading ticket list more', data)
    commit('setLoadingTicketListMore', data)
  },

  changeLoadingMessageMore ({ commit }, data) {
    console.log('loading message more', data)
    commit('setLoadingMessageMore', data)
  },

  changeLoadingCreateTicket ({ commit }, data) {
    console.log('loading create ticket', data)
    commit('setLoadingCreateTicket', data)
  },

  changeLoadingAssignEmployees ({ commit }, data) {
    console.log('loading assign employees', data)
    commit('setLoadingAssignEmployees', data)
  },

  changeLoadingCloseTicket ({ commit }, data) {
    console.log('loading close ticket', data)
    commit('setLoadingCloseTicket', data)
  },

  changeLoadingChangeOrderId ({ commit }, data) {
    console.log('loading change order', data)
    commit('setLoadingChangeOrderId', data)
  }
}
