<template>
  <div class="page-title">
    <h1 v-if="type === 'client'" class="title is-3 has-text-centered has-text-primary">
      <slot />
    </h1>

    <nav v-if="type === 'panel'" class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-3">
            <slot />
          </h1>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <div class="buttons">
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'client'
    }
  }
}
</script>
