<template>
  <div class="header-info">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {

}
</script>
