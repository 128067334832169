<template>
  <b-modal
    :active.sync="activeModal"
    :can-cancel="false"
    has-modal-card
    class="location-settings-modal"
  >
    <editAddress v-show="editAddress" ref="editAddress" @status="x => editAddress = x" />
    <div v-show="!editAddress" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span>
            {{ $t('header.locationSettings.modal.title') }}
          </span>
        </p>
        <button
          type="button"
          class="delete"
          @click="activeModal = false"
        />
      </header>
      <section class="modal-card-body">
        <div>
          <b-loading :is-full-page="false" :active.sync="loading" />

          <div class="columns">
            <div class="column">
              <span class="label">{{ $t('header.locationSettings.modal.language') }}</span>
              <b-select v-model="_locale" expanded @input="switchLanguage">
                <option
                  v-for="(lang, index) in _locales"
                  :key="index"
                  :value="lang"
                >
                  {{ $t(`locales.${lang}`) }}
                </option>
              </b-select>
            </div>
            <div class="column">
              <span class="label">{{ $t('header.locationSettings.modal.currency') }}</span>
              <b-select v-model="_currency" expanded>
                <option :value="_currency">
                  {{ _currency }}
                </option>
              </b-select>
            </div>
          </div>

          <template v-if="checkIsAddress()">
            <span class="label">{{ $t('admin.orderDetails.deliveryAddress') }}</span>
            <div class="address-items">
              <div v-for="item in addresses" :key="item.id" :class="item.mainAddress ? 'address-item main' : 'address-item'">
                <div>
                  <p>
                    <span>{{ item.company }}</span>
                  </p>
                  <p>
                    <span>{{ item.firstName }}</span>
                    <span>{{ item.lastName }}</span>
                  </p>
                  <p>
                    <span>{{ item.address }}</span>
                    <span>{{ item.addressNumber }}</span>
                  </p>
                  <p>
                    <span>{{ item.postCode }}</span>
                    <span>{{ item.city }}, {{ getCountry(item.countryId) ? getCountry(item.countryId).iso : '' }}</span>
                  </p>
                  <p>
                    <span>{{ item.phone }}</span>
                    <span>{{ item.email }}</span>
                  </p>
                </div>
                <div class="action-links">
                  <p v-if="!item.mainAddress && addresses.length > 1" @click.prevent="setMain(item.id)">
                    {{ $t('actions.setMain') }}
                  </p>
                  <p @click.prevent="formAddress(item)">
                    {{ $t('actions.edit') }}
                  </p>
                  <p v-if="!item.mainAddress && addresses.length > 1" @click.prevent="deleteAddress(item.id)">
                    {{ $t('actions.delete') }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <div v-else class="columns">
            <div class="column">
              <span class="label">{{ $t('admin.orderDetails.deliveryAddress') }}</span>
              <div class="buttons">
                <b-button type="is-primary" icon-left="plus" outlined @click="formAddress(null)">
                  {{ $t('header.locationSettings.modal.addAddress') }}
                </b-button>
              </div>
            </div>
            <div class="column">
              <b-field :label="$t('fields.deliveryCountry')">
                <b-select
                  v-model="deliveryCountry"
                  name="country"
                  expanded
                  @input="changeCountry"
                >
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons">
          <b-button :label="$t('actions.close')" @click="activeModal = false" />
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import EditAddress from './Form'
export default {
  components: { EditAddress },
  data () {
    return {
      loading: false,
      activeModal: false,
      editAddress: false,
      deliveryCountry: null
    }
  },
  computed: {
    mainAddress () {
      return this.$store.state.page.mainAddress
    },
    addresses () {
      return this.$store.state.page.addresses
    },
    countries () {
      return this.$store.state.page.countries
    }
  },
  watch: {
    mainAddress: {
      handler () {
        if (this.mainAddress && this.mainAddress.countryId) {
          this.deliveryCountry = this.mainAddress.countryId
        } else if (this.$store.state.page.countryId) {
          this.deliveryCountry = this.$store.state.page.countryId
        }
      },
      deep: true
    }
  },
  created () {
    if (this.mainAddress && this.mainAddress.countryId) {
      this.deliveryCountry = this.mainAddress.countryId
    } else if (this.$store.state.page.countryId) {
      this.deliveryCountry = this.$store.state.page.countryId
    }
  },
  methods: {
    checkIsAddress () {
      if (this.addresses && this.addresses.length >= 2) {
        return true
      } else if (this.addresses && this.addresses.length === 1) {
        let notNull = 0
        Object.keys(this.addresses[0]).forEach((key) => {
          if (key !== 'countryId' && this.addresses[0][key] !== null) {
            notNull++
          }
        })
        if (notNull >= 2) {
          return true
        }
      }
      return false
    },
    open () {
      this.activeModal = true
      if (!this.addresses) {
        this.getAddresses()
      }
    },
    getAddresses () {
      this.loading = true
      this.$api.address.getAll()
        .then((res) => {
          this.saveAddresses(res.addresses)
          this.saveMainAddress(res.main)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formAddress (address) {
      this.$refs.editAddress.open(address)
    },
    setMain (id) {
      if (id) {
        this.loading = true
        this.$api.address.setMain(id)
          .then((res) => {
            this.saveAddresses(res.addresses)
            this.saveMainAddress(res.main)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    deleteAddress (id) {
      this.$api.address.delete(id)
        .then((res) => {
          this.saveAddresses(res.addresses)
        })
    },
    saveAddresses (data) {
      this.$store.dispatch('page/update', { name: 'addresses', data })
    },
    saveMainAddress (data) {
      this.$store.dispatch('page/update', { name: 'mainAddress', data })
    },
    switchLanguage (lang) {
      this.$router.push(this.switchLocalePath(lang))
    },
    getCountry (countryId) {
      return this.$store.state.page.countries.find(x => x.id === countryId)
    },
    changeCountry (countryId) {
      this.loading = true
      this.$api.address.save({
        id: this.mainAddress && this.mainAddress.id ? this.mainAddress.id : null,
        countryId
      })
        .then((res) => {
          this.saveAddresses(res.addresses)
          this.saveMainAddress(res.main)

          if (res.cookies) {
            this.$cookies.set('address', res.cookies, { path: '/', domain: process.env.cookieDomain, maxAge: 60 * 60 * 24 * 365 })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
