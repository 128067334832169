<script>
import lodashClonedeep from 'lodash.clonedeep'

import at from '~/marketplace-front-core/static/icons/flags/at.svg'
import be from '~/marketplace-front-core/static/icons/flags/be.svg'
import bg from '~/marketplace-front-core/static/icons/flags/bg.svg'
import ch from '~/marketplace-front-core/static/icons/flags/ch.svg'
import cz from '~/marketplace-front-core/static/icons/flags/cz.svg'
import de from '~/marketplace-front-core/static/icons/flags/de.svg'
import dk from '~/marketplace-front-core/static/icons/flags/dk.svg'
import ee from '~/marketplace-front-core/static/icons/flags/ee.svg'
import en from '~/marketplace-front-core/static/icons/flags/en.svg'
import es from '~/marketplace-front-core/static/icons/flags/es.svg'
import fi from '~/marketplace-front-core/static/icons/flags/fi.svg'
import fr from '~/marketplace-front-core/static/icons/flags/fr.svg'
import gb from '~/marketplace-front-core/static/icons/flags/gb.svg'
import gr from '~/marketplace-front-core/static/icons/flags/gr.svg'
import hr from '~/marketplace-front-core/static/icons/flags/hr.svg'
import hu from '~/marketplace-front-core/static/icons/flags/hu.svg'
import ie from '~/marketplace-front-core/static/icons/flags/ie.svg'
import is from '~/marketplace-front-core/static/icons/flags/is.svg'
import it from '~/marketplace-front-core/static/icons/flags/it.svg'
import lt from '~/marketplace-front-core/static/icons/flags/lt.svg'
import lu from '~/marketplace-front-core/static/icons/flags/lu.svg'
import lv from '~/marketplace-front-core/static/icons/flags/lv.svg'
import mt from '~/marketplace-front-core/static/icons/flags/mt.svg'
import nl from '~/marketplace-front-core/static/icons/flags/nl.svg'
import no from '~/marketplace-front-core/static/icons/flags/no.svg'
import pl from '~/marketplace-front-core/static/icons/flags/pl.svg'
import pt from '~/marketplace-front-core/static/icons/flags/pt.svg'
import ro from '~/marketplace-front-core/static/icons/flags/ro.svg'
import se from '~/marketplace-front-core/static/icons/flags/se.svg'
import si from '~/marketplace-front-core/static/icons/flags/si.svg'
import sk from '~/marketplace-front-core/static/icons/flags/sk.svg'
import tr from '~/marketplace-front-core/static/icons/flags/tr.svg'
import unknown from '~/marketplace-front-core/static/icons/flags/unknown.svg'

import toSlugText from '../utils/toSlugText'

export default {
  computed: {
    _flags () {
      return {
        at,
        be,
        bg,
        ch,
        cz,
        de,
        dk,
        ee,
        en,
        es,
        fi,
        fr,
        gb,
        gr,
        hr,
        hu,
        ie,
        is,
        it,
        lt,
        lu,
        lv,
        mt,
        nl,
        no,
        pl,
        pt,
        ro,
        se,
        si,
        sk,
        tr,
        unknown
      }
    },
    _baseURL () {
      return process.env.baseURL
    },
    _frontBaseUrl () {
      return process.env.baseURL
    },
    _user () {
      return this.$store.state.auth.user
    },
    _locale: {
      get () {
        return this.$store.state.i18n.locale
      },
      set (newLang) {
        this.$router.push(this.switchLocalePath(newLang))
      }
    },
    _locales () {
      return process.env.langs.split('|')
    },
    _currency () {
      return this.$store.state.page.currency
    },
    _project () {
      return process.env.project
    },
    _slugRegulations () {
      return JSON.parse(process.env.slugRegulations)[this._locale]
    },
    _localesSeller () {
      const locales = process.env.langs.split('|')
      const addingLocales = process.env.addingLangsSeller ? process.env.addingLangsSeller.split('|') : []

      return [...locales, ...addingLocales]
    },
    _currentTime () {
      return this.$store.getters['websocket/currentTimeMessenger']
    }
  },
  methods: {
    _clone (obj) {
      return lodashClonedeep(obj)
    },
    _checkPage (page) {
      const name = this.$route.name.split('___')
      if (name && name[0]) {
        return name[0] === page
      }
    },
    sizeType (sizes) {
      if (sizes?.type && sizes.type === 'cm') {
        return this.$t('fields.cm')
      }

      if (sizes?.type && sizes.type === 'inch') {
        return this.$t('fields.inch')
      }

      return ''
    },
    getImageBySize (file, size) {
      if (file && file.sizes && size && file.sizes[size]) {
        const image = file.sizes[size]
        const imageWebp = file.sizes[`${size}-webp`]
        if (imageWebp) {
          return {
            src: imageWebp,
            webpFallback: image
          }
        } else {
          return {
            src: image,
            webpFallback: null
          }
        }
      } else {
        return {
          src: null,
          webpFallback: null
        }
      }
    },
    toSlugTitle (description) {
      const text = description && description.name ? description.name : 'product'
      return toSlugText(text)
    },
    toSlugText (text) {
      return toSlugText(text)
    },
    toCurrency (data, type = null, currency = process.env.currency) {
      let value = data

      if (type) {
        if (data.length > 0) {
          const find = data.find(x => x.type === type)
          if (find) {
            if (find.price || find.price === 0) {
              value = find.price
            } else {
              value = find.brutto
            }
            currency = find.currency
          } else {
            return ''
          }
        } else {
          return ''
        }
      }

      if (typeof value !== 'number') {
        return ''
      }

      const formatter = new Intl.NumberFormat(this._locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    findDescriptions (array, lang) {
      if (!lang) { lang = this._locale }
      const findIndex = array.findIndex(x => x.lang === lang)

      if (findIndex >= 0) {
        return findIndex
      } else {
        const getId = array.push({
          id: null,
          name: null,
          nameSeo: null,
          description: null,
          descriptionSeo: null,
          lang
        })

        return getId - 1
      }
    },
    findPrices (array, type = 'base') {
      const findIndex = array.findIndex(x => x.type === type)
      if (findIndex >= 0) {
        return findIndex
      } else {
        const getId = array.push({
          id: null,
          brutto: '',
          type,
          currency: process.env.currency
        })

        return getId - 1
      }
    },
    logout () {
      this.$store.dispatch('auth/reset').then(() => {
        this.$router.push(this.localePath('index'))
        this.$store.dispatch('page/update', { name: 'cart', data: null })
        this.$store.dispatch('page/update', { name: 'countNewMessages', data: 0 })

        this.$api.cart.get()
          .then((res) => {
            this.$store.dispatch('page/update', { name: 'cart', data: res })
          })
          .catch(() => {
            this.$store.dispatch('page/update', { name: 'cart', data: null })
          })

        this.$api.address.getAll()
          .then((res) => {
            this.$store.dispatch('page/update', { name: 'addresses', data: res.addresses })
            this.$store.dispatch('page/update', { name: 'mainAddress', data: res.main })
          })
          .catch(() => {
            this.$store.dispatch('page/update', { name: 'addresses', data: null })
            this.$store.dispatch('page/update', { name: 'mainAddress', data: null })
          })
      })

      this.$store.dispatch('websocket/close')
      this.initWebSocket()
    },
    scrollToTop (type = 'client', behavior = 'auto', top = 0) {
      window.scrollTo({ top, behavior })
    },
    scrollToFirstError () {
      if (this.errors.items && this.errors.items[0]) {
        let element = document.getElementsByName(this.errors.items[0].field)[0]
        let lastElement = null
        let top = 0
        let content = null

        while (element !== null && element.tagName !== 'HTML') {
          const classes = []
          element.classList.forEach((className) => { classes.push(className) })

          if (classes.includes('field') && !classes.includes('is-floating-label')) {
            top = element.offsetTop
          }

          if (!content && classes.includes('modal-card-body')) {
            content = element
            element = null
          }

          if (lastElement && element === lastElement.offsetParent) {
            top += element.offsetTop
          }

          if (element) {
            lastElement = element
            element = element.parentElement
          }
        }

        top -= 10

        if (content) {
          content.scrollTo({ top, behavior: 'smooth' })
        } else {
          window.scrollTo({ top, behavior: 'smooth' })
        }
      }
    },
    metaLink (links) {
      const findIndex = links.findIndex(x => x.hreflang === 'x-default')
      if (findIndex >= 0) {
        this.$delete(links, findIndex)
      }

      if (process.env.seoLang) {
        try {
          const seoLang = JSON.parse(process.env.seoLang)
          const keys = Object.keys(seoLang)

          links.forEach((element) => {
            if (
              keys.includes(element.hreflang) &&
              seoLang[element.hreflang] &&
              Array.isArray(seoLang[element.hreflang]) &&
              seoLang[element.hreflang].length
            ) {
              seoLang[element.hreflang].forEach((lang, index) => {
                if (index === 0) {
                  element.hreflang = lang
                } else {
                  const newElement = this._clone(element)
                  newElement.hreflang = lang
                  links.push(newElement)
                }
              })
            }
          })
        } catch (e) {

        }
      }
      return links
    },
    truncate (text, limit) {
      return (text.length <= limit) ? text : text.slice(0, limit) + '...'
    },
    initWebSocket () {
      try {
        if (!this.$nuxt.$store.state.websocket.isWebSocketConnection) {
          console.log(`%c${this._currentTime} %cCONNECTION TO WEBSOCKET`, 'color: #CEC4fA;', 'background: #6638AC; font-size: 0.75rem; color: #CEC4fA;')
          const token = this.$cookies.get('token')
          const sessionAPI = this.$cookies.get('sessionAPI')
          if (token) {
            this.connectWebSocket('?token=' + token)
          } else if (sessionAPI) {
            this.connectWebSocket('?PHPSESSID=' + sessionAPI)
          } else {
            this.$api.auth.session()
              .then((res) => {
                this.$cookies.set('sessionAPI', res.PHPSESSID, { path: '/', maxAge: 60 * 60 * 24 * 30 })
                this.connectWebSocket('?PHPSESSID=' + res.PHPSESSID)
              })
          }
        } else if (this.getRouteBaseName() !== 'messenger') {
          this.$store.dispatch('websocket/onMessage')
        }
      } catch (e) {
        console.error(`${this._currentTime} ERROR CONNECTION WEBSOCKET`)
      }
    },
    connectWebSocket (token) {
      const url = process.env.webSocketUrl
      const wb = new WebSocket(url + token)

      this.$store.dispatch('websocket/connection', wb)
      this.$store.dispatch('websocket/onopen')
      this.$store.dispatch('websocket/onMessage')

      wb.onclose = (e) => {
        console.warn(`${this._currentTime} CLOSE WEBSOCKET`)
        this.$store.dispatch('websocket/setIsWebSocketConnection', false)
        setTimeout(function () {
          if (!this.$nuxt.$store.state.websocket.isWebSocketConnection) {
            this.$nuxt.initWebSocket()
            this.$nuxt.$store.dispatch('websocket/sendLastMessage')
          }
        }, 10000)
      }
      wb.onerror = (e) => {
        console.error(`${this._currentTime} ERROR WEBSOCKET`)
        this.$store.dispatch('websocket/setIsWebSocketConnection', false)
      }
    }
  }
}
</script>
