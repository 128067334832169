import Vue from 'vue'
import globalMixin from '~/marketplace-front-core/mixin/globalMixin'
import event from '~/marketplace-front-core/mixin/event'
import TableEmpty from '~/marketplace-front-core/components/admin/shared/TableEmpty'
import RenderNode from '~/marketplace-front-core/components/RenderNode'
import AdminLayout from '~/marketplace-front-core/components/layout/AdminLayout'
import AdminTab from '~/marketplace-front-core/components/layout/AdminTab'
import projectEvent from '~/mixin/projectEvent'
import PageTitle from '~/components/layout/PageTitle'

Vue.mixin(globalMixin)
Vue.mixin(event)
Vue.mixin(projectEvent)

Vue.component('RenderNode', RenderNode)
Vue.component('PageTitle', PageTitle)
Vue.component('AdminLayout', AdminLayout)
Vue.component('AdminTab', AdminTab)
Vue.component('TableEmpty', TableEmpty)
