import jwtDecode from 'jwt-decode'

export const coreState = () => ({
  user: null,
  token: null,
  refreshToken: null,
  redirectPath: null
})

export const coreMutations = {
  set_user (store, data) {
    store.user = data
  },
  set_tokens (store, data) {
    store.token = data.token
    store.refreshToken = data.refreshToken
  },
  set_redirect_path (store, data) {
    store.redirectPath = data
  },
  reset_user (store) {
    store.user = null
    store.refreshToken = null
    store.token = null
    store.redirectPath = null
  }
}

export const coreActions = {
  set ({ commit }, data) {
    if (data && data.token && jwtDecode(data.token)) {
      const decode = jwtDecode(data.token)

      commit('set_user', {
        email: decode.email,
        avatar: decode.avatar,
        firstName: decode.firstname,
        lastName: decode.lastname,
        displayName: decode.displayName,
        roles: decode.roles,
        confirmed: {
          email: decode.emailConfirmed,
          phone: decode.phoneExist ? decode.phoneConfirmed : !(process.env.requiredPhoneToRegistration === 'true')
        },
        tradeCredit: decode.tradeCredit || false
      })
      commit('set_tokens', { token: data.token, refreshToken: data.refreshToken })

      this.$cookies.set('refreshToken', data.refreshToken, { path: '/', maxAge: 60 * 60 * 24 * 30 })
      this.$cookies.set('token', data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 })
      this.$axios.setToken(data.token, 'Bearer')
    }
  },
  reset ({ commit }) {
    commit('reset_user')
    this.$cookies.remove('token')
    this.$cookies.remove('refreshToken')
    this.$axios.setToken(false)
    return Promise.resolve()
  },
  pageToRedirect ({ commit }, data) {
    commit('set_redirect_path', data)
    return Promise.resolve()
  }
}
