<template>
  <div class="breadcrumbs">
    <client-only>
      <div class="container main-container">
        <div class="breadcrumbs-container">
          <nuxt-link :to="localePath('index')" class="breadcrumbs-logo">
            <img src="~/static/images/werbe-pro.svg" alt="Werbe" width="24" height="24">
          </nuxt-link>
          <nuxt-link :to="localePath('index')" class="breadcrumbs-homename">
            {{ homeName }}
          </nuxt-link>

          <div v-for="(row, index) in $store.state.page.breadcrumbs" :key="index" class="breadcrumbs-item">
            <span :key="'sign' + index" class="breadcrumbs-arrow" />
            <nuxt-link v-if="row.url" :key="'link' + index" :to="row.url" :class="{ 'active': last(index + 1, row.text) }">
              {{ row.text }}
            </nuxt-link>
            <span v-else :key="'text' + index">{{ row.text }}</span>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  data () {
    return {
      homeName: process.env.breadcrumbsHomeName
    }
  },
  methods: {
    last (index, text) {
      return this.$store.state.page.breadcrumbs.length === index
    }
  }
}
</script>
