import toSlug from './toSlug'

export default function toSlugText (text) {
  const maxLength = 100

  text = text || ''

  if (text.length > maxLength) {
    const count = text.substring(0, maxLength).split(' ').length
    const newText = text.split(' ')
    newText.length = count
    text = newText.join(' ')
  }

  return toSlug(text)
}
