import { Validator } from 'vee-validate'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import axios from 'axios'
/* ------------- PHONE VALIDATION ------------- */
const phoneValidation = (value) => {
  return new Promise((resolve) => {
    const phoneNumber = value.replace(/\s/g, '')
    const parse = parseMax(phoneNumber)
    return resolve(!!(parse && parse.isValid()))
  })
}
Validator.extend('phone', {
  validate: phoneValidation
})
/* ------------- IS-UNIQUE-EMAIL VALIDATION ------------- */
const isUniqueEmail = value =>
  new Promise((resolve) => {
    setTimeout(() => {
      axios
        .post(process.env.baseURL + '/registration/validate/email', {
          email: value
        })
        .then((result) => {
          if (result.data.data === true) {
            return resolve({
              valid: false
            })
          } else {
            return resolve({
              valid: true
            })
          }
        })
    }, 200)
  })
Validator.extend('uniqueEmail', {
  validate: isUniqueEmail,
  getMessage: (field, params, data) => data.message
})
/* ------------- IS-UNIQUE-EMAIL VALIDATION ------------- */
const isUniquePhone = value =>
  new Promise((resolve) => {
    setTimeout(() => {
      axios
        .post(process.env.baseURL + '/registration/validate/phone', {
          phone: value
        })
        .then((result) => {
          if (result.data.data === true) {
            return resolve({
              valid: false
            })
          } else {
            return resolve({
              valid: true
            })
          }
        })
    }, 200)
  })
Validator.extend('uniquePhone', {
  validate: isUniquePhone,
  getMessage: (field, params, data) => data.message
})
/* ------------- ZIP-CODE VALIDATION ------------- */
Validator.extend('zip_code', {
  getMessage: field => 'The ' + field + ' value is not truthy.',
  validate: value => !!value
})

/* ------------- UNSUPPORTED-ADDRESS VALIDATION ------------- */

const isUnsupportedAddress = (value, validate) =>
  new Promise((resolve) => {
    return resolve({
      valid: !(validate[0] === 'true')
    })
  })

Validator.extend('unsupportedAddress', {
  validate: isUnsupportedAddress,
  getMessage: (field, params, data) => data.message
})
