<script>
import CryptoJS from 'crypto-js'

export default {
  methods: {
    // Wyświetlenie produktu
    eventProductView (product, userId) {
      this.projectEventProductView(product, userId)

      // ---------------------------------------------------- //
      // -------------------- GA 4 && GTM ------------------- //
      // ---------------------------------------------------- //

      const item = {
        item_id: product.id,
        item_name: product.description.name,
        item_category: product?.categories[0]?.description?.name || 'Brak kategorii',
        quantity: product.minimalQuantity && product.minimalQuantity >= 1 ? product.minimalQuantity : 1,
        price: product.summary.price
      }

      const attributes = {
        currency: product.summary.currency,
        value: product.summary.price,
        items: [item]
      }

      if (this.$gtag) {
        this.$gtag('event', 'view_item', ...attributes)
      }

      if (this.$gtm) {
        this.$gtm.push({ event: 'view_item', ...attributes })
      }

      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        this.$fb.fbq('track', 'ViewContent', {
          content_ids: product.sku || product.id,
          currency: product.summary.currency,
          value: product.summary.price
        // userId
        })
      }

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        this.$ga.ecommerce.addProduct({
          id: product.id,
          name: product.description.name
        })

        this.$ga.ecommerce.setAction('detail')

        this.$ga.ecommerce.send('pageview')
      }
    },

    // Dodanie do koszyka
    eventProductAddToCart (product, prices, quantity) {
      this.projectEventProductAddToCart(product, prices, quantity)

      // ---------------------------------------------------- //
      // -------------------- GA 4 && GTM ------------------- //
      // ---------------------------------------------------- //

      const item = {
        item_id: product.id,
        item_name: product.description.name,
        quantity,
        price: product.summary.price,
        item_category: product?.categories[0]?.description?.name || 'Brak kategorii'
      }

      const attributes = {
        currency: prices.currency,
        value: prices.totalPrice,
        items: [item]
      }

      if (this.$gtag) {
        this.$gtag('event', 'add_to_cart', ...attributes)
      }

      if (this.$gtm) {
        this.$gtm.push({ event: 'add_to_cart', ...attributes })
      }

      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        this.$fb.fbq('track', 'AddToCart', {
          content_name: product.description.name,
          content_ids: product.sku || product.id,
          content_category: product?.categories[0]?.description?.name || 'Brak kategorii',
          currency: prices.currency,
          value: prices.price
        })
      }

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        this.$ga.ecommerce.addProduct({
          id: product.id,
          name: product.description.name,
          price: prices.price,
          quantity
        })

        this.$ga.ecommerce.setAction('add')

        this.$ga.ecommerce.send('event', 'UX', 'click', 'add to cart')
      }
    },

    // Usunięcie z koszyka
    eventRemoveProductFromCart (product) {
      this.projectEventRemoveProductFromCart(product)

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        this.$ga.ecommerce.addProduct({
          id: product.offerProductId,
          name: product.description.name,
          price: product.summary.price,
          quantity: product.quantity
        })

        this.$ga.ecommerce.setAction('add')

        this.$ga.ecommerce.send('event', 'UX', 'click', 'remove from cart')
      }
    },

    // Wejście w stronę kasy
    eventCheckoutView (orders) {
      this.projectEventCheckoutView(orders)

      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        const finalTotalPrice = orders[0].totals.find(
          price => price.type === 'finalTotal'
        )

        const contents = orders[0].products.map((product) => {
          return {
            id: product.sku || product.offerProductId,
            quantity: product.quantity
          }
        })

        this.$fb.fbq('track', 'InitiateCheckout', {
          contents,
          currency: orders[0].currency,
          num_items: orders[0].quantity,
          value: finalTotalPrice.price
        })
      }

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        orders.forEach((order) => {
          order.products.forEach((product) => {
            this.$ga.ecommerce.addProduct({
              id: product.offerProductId,
              name: product.description.name,
              price: product.summary.price,
              quantity: product.quantity
            })
          })
        })

        this.$ga.ecommerce.setAction('checkout', { step: 1 })
        this.$ga.ecommerce.send('pageview')
      }
    },

    // Zmiana metody płatności
    eventCheckoutOptionPayment (option, orders) {
      this.projectEventCheckoutOptionPayment(option, orders)

      // ---------------------------------------------------- //
      // -------------------- GA 4 && GTM ------------------- //
      // ---------------------------------------------------- //

      orders.forEach((order) => {
        const items = []
        order.products.forEach((product) => {
          items.push({
            item_id: product.offerProductId,
            item_name: product.description.name,
            item_category: product?.category || 'Brak kategorii',
            quantity: product.quantity,
            price: product.summary.price
          })
        })

        const attributes = {
          currency: order.currency,
          value: order.totals.find(x => x.type === 'finalTotal')?.price || 0,
          payment_type: option,
          items
        }

        if (this.$gtag) {
          this.$gtag('event', 'add_payment_info', ...attributes)
        }

        if (this.$gtm) {
          this.$gtm.push({ event: 'add_payment_info', ...attributes })
        }
      })

      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        this.$fb.fbq('track', 'AddPaymentInfo')
      }

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        this.$ga.ecommerce.setAction('checkout', { step: 3, option })
        this.$ga.ecommerce.send('event', 'Checkout', 'Option')
      }
    },

    // Zmiana metody wysyłki
    eventCheckoutOptionShipping (option, orders) {
      this.projectEventCheckoutOptionShipping(option, orders)

      // ---------------------------------------------------- //
      // -------------------- GA 4 && GTM ------------------- //
      // ---------------------------------------------------- //

      orders.forEach((order) => {
        const items = []
        order.products.forEach((product) => {
          items.push({
            item_id: product.offerProductId,
            item_name: product.description.name,
            item_category: product?.category || 'Brak kategorii',
            quantity: product.quantity,
            price: product.summary.price
          })
        })

        const attributes = {
          currency: order.currency,
          value: order.totals.find(x => x.type === 'finalTotal')?.price || 0,
          shipping_tier: option,
          items
        }

        if (this.$gtag) {
          this.$gtag('event', 'add_shipping_info', ...attributes)
        }

        if (this.$gtm) {
          this.$gtm.push({ event: 'add_shipping_info', ...attributes })
        }
      })

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        this.$ga.ecommerce.setAction('checkout', { step: 2, option })
        this.$ga.ecommerce.send('event', 'Checkout', 'Option')
      }
    },

    // Zakup
    eventPurchaseCheckout (orders, information, address, countries) {
      this.projectEventPurchaseCheckout(orders, information, address, countries)

      // ---------------------------------------------------- //
      // -------------------- GA 4 && GTM ------------------- //
      // ---------------------------------------------------- //

      orders.forEach((order) => {
        const items = []
        order.products.forEach((product) => {
          items.push({
            item_id: product.offerProductId,
            item_name: product.description.name,
            item_category: product?.category || 'Brak kategorii',
            quantity: product.quantity,
            price: product.summary.price
          })
        })

        const attributes = {
          transaction_id: order.orderId,
          value: order.totals.find(x => x.type === 'finalTotal')?.price || 0,
          tax: order.totals.find(x => x.type === 'vat')?.price || 0,
          shipping: order.totals.find(x => x.type === 'shipping')?.price || 0,
          currency: order.currency,
          coupon: information?.code || '',
          items
        }

        if (this.$gtag) {
          this.$gtag('event', 'purchase', ...attributes)
        }

        if (this.$gtm) {
          this.$gtm.push({ event: 'purchase', ...attributes })
        }
      })

      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        const finalTotalPrice = orders[0].totals.find(
          price => price.type === 'finalTotal'
        )

        const contents = orders[0].products.map((product) => {
          return {
            id: product.sku || product.offerProductId,
            quantity: product.quantity
          }
        })

        const hashText = function (text) {
          if (text) {
            const normalized = text.toLowerCase().replace(/ /g, '')
            const hash = CryptoJS
              .SHA256(normalized)
              .toString(CryptoJS.enc.Hex)
            return hash
          }

          return null
        }

        const findCountry = countries.find(x => x.id === address.country)

        const userData = {
          em: hashText(address.email),
          ct: hashText(address.city),
          st: hashText(address.address),
          zp: hashText(address.postCode)
        }
        // ge: 'płeć f - kobieta | m - mężczyzna',

        if (address.firstName) {
          userData.fn = hashText(address.firstName)
        }

        if (address.lastName) {
          userData.ln = hashText(address.lastName)
        }
        if (findCountry) {
          userData.country = hashText(findCountry.iso)
        }

        this.$fb.fbq('track', 'Purchase', {
          value: finalTotalPrice.price,
          currency: orders[0].currency,
          contents,
          user_data: userData
        })
      }

      // ---------------------------------------------------- //
      // ----------------- Google Analytics ----------------- //
      // ---------------------------------------------------- //

      if (this.$ga) {
        const searchPrice = function (prices, type) {
          if (prices && prices.length > 0) {
            const find = prices.find(x => x.type === type)
            if (find) { return find }
          }
          return null
        }

        // ecommerce
        orders.forEach((order) => {
          const transaction = {
            id: order.orderId,
            affiliation: 'SHOP',
            currency: order.currency
          }

          if (searchPrice(order.totals, 'finalTotal')) {
            transaction.revenue = searchPrice(order.totals, 'finalTotal').price
          }

          if (searchPrice(order.totals, 'shipping')) {
            transaction.shipping = searchPrice(order.totals, 'shipping').price
          }

          if (searchPrice(order.totals, 'vat')) {
            transaction.tax = searchPrice(order.totals, 'vat').price
          }

          this.$ga.ecommerce.addTransaction(transaction)

          // console.log('transaction', transaction)

          order.products.forEach((product) => {
            const item = {
              id: order.orderId,
              name: product.description.name,
              quantity: product.quantity
            }

            if (product.summary && product.summary.basePrice) {
              item.price = product.summary.basePrice
              item.currency = product.summary.currency
            }

            if (product.sku) { item.sku = product.sku }

            // console.log('item', item)

            this.$ga.ecommerce.addItem(item)
          })

          this.$ga.ecommerce.send()
        })

        // enhanced-ecommerce
        orders.forEach((order) => {
          order.products.forEach((product) => {
            const addProduct = {
              id: product.offerProductId,
              name: product.description.name,
              quantity: product.quantity
            }

            if (product.summary && product.summary.basePrice) { addProduct.price = product.summary.basePrice }

            // console.log('addProduct', addProduct)

            this.$ga.ecommerce.addProduct(addProduct)
          })

          const purchase = {
            id: order.orderId,
            affiliation: 'SHOP'
          }

          if (searchPrice(order.totals, 'finalTotal')) {
            purchase.revenue = searchPrice(order.totals, 'finalTotal').price
          }

          if (searchPrice(order.totals, 'shipping')) {
            purchase.shipping = searchPrice(order.totals, 'shipping').price
          }

          if (searchPrice(order.totals, 'vat')) {
            purchase.tax = searchPrice(order.totals, 'vat').price
          }

          // console.log('purchase', purchase)

          this.$ga.ecommerce.setAction('purchase', purchase)

          this.$ga.ecommerce.send('pageview')
        })
      }
    },

    // Wejście na stronę po pozytywnym zakupie
    eventPurchaseSuccessPage (orders) {
      this.projectEventPurchaseSuccessPage(orders)
    },

    // Zmiana rozmiarów
    eventCustomizeProduct (product, size) {
      // ---------------------------------------------------- //
      // ---------------------- PIXEL ----------------------- //
      // ---------------------------------------------------- //

      if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
        this.$fb.fbq('track', 'CustomizeProduct', {
          content_name: product.description.name,
          content_ids: product.sku || product.id,
          content_category: product?.categories[0]?.description?.name || 'Brak kategorii',
          currency: product.summary.currency,
          value: product.summary.price
        // size
        })
      }
    }
  }
}

// event
// GTM - GOOGLE TAG MANAGER
// this.$gtm.push({ event: 'myEvent', ...someAttributes })
// GTAG - GA4
// this.$gtag('event', 'your_event', { /* track something awesome */})
</script>
