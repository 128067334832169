<template>
  <header>
    <header-info v-if="checkPageCheckout">
      <div class="modal-link">
        <b-icon size="is-small" icon="checkmark-solid" />
        <a @click="toggleModal()">
          Bestpreis-Garantie
        </a>
      </div>
      <div class="header-info-content">
        Unser Angebot richtet sich ausschließlich an Unternehmen, Gewerbetreibende, Freiberufler, Institutionen und Vereine
      </div>
      <div class="header-info-contact">
        <div class="modal-link">
          <b-icon size="is-small" icon="phone" />
          <a href="tel:+497253-9353095">
            07253-9353095
          </a>
        </div>
        <div class="modal-link">
          <b-icon size="is-small" icon="mail" />
          <a target="_blank" href="mailto: info@werbeartikel-pro.de">
            info@werbeartikel-pro.de
          </a>
        </div>
      </div>
    </header-info>

    <b-modal
      v-model="modal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <div class="modal-card" style="max-width: 640px; border-radius: 6px;">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Werbeartikel Bestpreis-Garantie
          </p>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <h3 style="font-size: 20px;">
              So funktioniert unsere Werbeartikel-Pro Bestpreis-Garantie:
            </h3>
            <p>
              Einfach und simpel.
            </p>
            <ol>
              <li>Kaufen Sie Ihre gewünschten Produkte bei Werbeartikel-Pro.de</li>
              <li>Falls Sie innerhalb von 7 Tagen ab Ihrer Bestellung bei uns einen Kostenvoranschlag eines konkurrierenden Werbeartikel Händlers mit Sitz in Deutschland schicken, welcher Ihnen genau die gleichen Produkte, in gleicher     Menge, bei gleicher Veredelung und gleicher Lieferzeit günstiger anbietet, dann erstatten wir Ihnen nicht nur den Netto-Differenzbetrag, sondern auch noch einmal zusätzlich 5% der Netto-Warenwert-Summe.</li>
            </ol>
            <p>
              Haben Sie Fragen? Kein Problem, rufen Sie uns an oder <a target="_blank" href="mailto: info@werbeartikel-pro.de"> schreiben Sie uns eine Email.</a> Wir sind für Sie da!
            </p>
          </div>
        </section>
      </div>
    </b-modal>

    <nav>
      <div class="container main-container">
        <div class="top-navigation">
          <logo-address />
          <header-circle-buttons />
        </div>
        <div v-if="checkPageCheckout" class="bottom-navigation">
          <div class="button-and-search">
            <b-button icon-left="categories" class="navigation-button categories-button" @click.prevent="toggleMenu()">
              <span class="is-hidden-mobile">
                Werbeartikel
              </span>
            </b-button>
            <search-products />
          </div>
          <div class="buttons featured-buttons">
            <nuxt-link :to="localePath('products-bestsellers')">
              <b-button icon-left="bestsellers" class="featured-button button-bestsellers-hover">
                {{ $t('fields.bestsellers') }}
              </b-button>
            </nuxt-link>
            <nuxt-link :to="localePath('products-novelties')">
              <b-button icon-left="new" class="featured-button button-novelties-hover">
                {{ $t('fields.novelties') }}
              </b-button>
            </nuxt-link>
            <nuxt-link :to="localePath('products-sale')">
              <b-button icon-left="sale" class="featured-button button-sale-hover">
                {{ $t('fields.sale') }}
              </b-button>
            </nuxt-link>
          </div>
        </div>
      </div>
    </nav>

    <breadcrumbs v-if="checkPageCheckout && checkPagePrint && checkPageIndex" />
    <client-only v-if="checkPageCheckout">
      <div class="new-categories">
        <div class="container">
          <carousel-categories />
        </div>
      </div>
    </client-only>
    <!-- <client-only v-if="checkPageCheckout">
      <categories ref="categories" />
    </client-only> -->

    <!--
    <div v-if="confirmed && (!confirmed.email || !confirmed.phone)" class="container m-b-20">
      <b-notification :closable="false" type="is-warning" class="">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <b-icon size="is-large" icon="alert" />
          </div>
          <div class="column">
            <p v-if="!confirmed.email">
              {{ $t('header.confirmed.email') }}
            </p>
            <p v-if="!confirmed.phone">
              {{ $t('header.confirmed.phone') }}
            </p>
          </div>
        </div>
      </b-notification>
    </div>
     -->
    <side-cart v-if="checkPageCheckout" />
  </header>
</template>

<script>
// import MenuCart from '~/marketplace-front-core/components/shared/MenuCart'
import Breadcrumbs from './Breadcrumbs'
import SideCart from '~/marketplace-front-core/components/shared/SideCart'
import HeaderInfo from '~/marketplace-front-core/components/layout/HeaderInfo'
import SearchProducts from '~/marketplace-front-core/components/layout/SearchProducts'
import mixinCountNewMessages from '~/marketplace-front-core/mixin/countNewMessages'
import LogoAddress from '~/components/layout/LogoAddress'
import HeaderCircleButtons from '~/components/layout/HeaderCircleButtons'

export default {
  components: {
    // MenuCart,
    SideCart,
    HeaderInfo,
    LogoAddress,
    HeaderCircleButtons,
    SearchProducts,
    Breadcrumbs,
    CarouselCategories: () => { if (process.client) { return import('./CarouselCategories.vue') } }
  },
  mixins: [mixinCountNewMessages],
  data () {
    return {
      modal: false
    }
  },
  computed: {
    checkPageIndex () {
      return !this._checkPage('index')
    },
    checkPagePrint () {
      return !this._checkPage('print')
    },
    checkPageCheckout () {
      return !this._checkPage('checkout')
    }
  },
  errorCaptured (err, vm, info) {
    console.log(`error: ${err.toString()} \ninfo: ${info}`)
    return false
  },
  methods: {
    closeMobileMenu () {
      if (this.$refs.menu && this.$refs.menu.isOpened) {
        this.$refs.menu.closeMenu()
      }
    },
    check (key) {
      if (this._user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }
        const check = availableRoles.filter(val =>
          this.user.roles.includes(val)
        )

        return check.length > 0
      } else {
        return false
      }
    },
    toggleMenu () {
      if (this.$refs.categories) {
        this.$refs.categories.toggleMenu()
      }
    },
    toggleModal () {
      this.modal = !this.modal
    }
  }
}
</script>

<style lang="scss" scoped>
.new-categories {
  padding: 24px 0;
  background-color: #F7F4F2;
}
</style>
