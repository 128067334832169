<script>
export default {
  methods: {
    projectEventProductView (product) {

    },

    projectEventProductAddToCart (product) {

    },

    projectEventRemoveProductFromCart (product) {

    },

    projectEventCheckoutView (orders) {

    },

    projectEventCheckoutOptionPayment (option) {

    },

    projectEventCheckoutOptionShipping (option) {

    },

    projectEventPurchaseCheckout (orders) {

    },

    projectEventPurchaseSuccessPage (orders) {

    }

  }
}
</script>
