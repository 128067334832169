<template>
  <div class="search-store-container">
    <div class="search-store">
      <b-autocomplete
        ref="searchInput"
        v-model="keyword"
        :data="data"
        field="sentence"
        expanded
        open-on-focus
        clearable
        :disabled="loading"
        :placeholder="$t('header.searchPlaceholder')"
        @input="search"
        @select="select"
        @keydown.enter.native.prevent="enter"
      >
        <template slot-scope="props">
          <div class="searchinput-item">
            {{ props.option.sentence }}
            <span v-if="props.option.type === 'category'">
              {{ $t('fields.category') }}
            </span>
          </div>
        </template>
      </b-autocomplete>
      <div v-if="isProductsPageCustom" class="control search-everywhere">
        <b-checkbox v-model="searchCategory">
          {{ $t('actions.searchCategory') }}
        </b-checkbox>
      </div>
    </div>
    <p class="control">
      <b-button type="is-primary" class="navigation-button search-button" :loading="loading" @click="goToSearchPage">
        <b-icon icon="magnify" />
      </b-button>
    </p>
  </div>
</template>

<script>
import debounce from '~/marketplace-front-core/utils/debounce'

export default {
  data () {
    return {
      keyword: null,
      data: [],
      isProductsPage: false,
      isProductsPageCustom: false,
      searchCategory: false,
      loading: false
    }
  },
  watch: {
    '$route.query': {
      handler (query) {
        if (query && query.search && this.keyword !== query.search) {
          this.keyword = query.search
          this.search()
        } else if (this.keyword !== (query.search || null)) {
          this.keyword = null
          this.search()
        }
      },
      deep: true,
      immediate: true
    },
    '$route.name': {
      handler (name) {
        this.isProductsPage = /^products/.test(name)
        this.isProductsPageCustom = /^products-/.test(name)
        if (!this.isProductsPageCustom) {
          this.searchCategory = true
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    select (val) {
      if (val && val.type) {
        if (val.type === 'category') {
          this.keyword = ''

          this.loading = true

          this.$router.push(this.localePath({
            name: 'products-category',
            params: {
              categoryName: val.slug || this.toSlugTitle({ name: val.sentence }),
              categoryId: val.categoryId
            }
          }),
          () => { this.loading = false },
          () => { this.loading = false }
          )
        }
        if (val.type === 'search') {
          this.keyword = val.sentence
          this.goToSearchPage()
        }
      }
    },
    enter () {
      if (!(this.$refs.searchInput.selected)) {
        this.goToSearchPage()
      }
    },
    search: debounce(function (val) {
      this.$api.default.search({ keyword: this.keyword })
        .then((res) => {
          this.data = res
        })
        .catch(() => {
          this.data = []
        })
    }),
    goToSearchPage () {
      if (this.isProductsPage && this.searchCategory) {
        const query = this._clone(this.$route.query)
        delete query.page

        if (this.keyword) {
          query.search = this.keyword
        } else {
          delete query.search
        }

        this.loading = true
        this.$router.push(
          { query },
          () => { this.loading = false },
          () => { this.loading = false }
        )
      } else {
        const query = {}
        if (this.keyword) { query.search = this.keyword }

        this.loading = true
        this.$router.push(
          this.localePath({ name: 'products', query }),
          () => { this.loading = false },
          () => { this.loading = false }
        )
      }
    }
  }
}
</script>
